import { Typography, Container, Button, Switch, Stack, FormControlLabel, TextField } from "@mui/material";
import { useState } from "react";

// Helper function to get the time zone abbreviation
function getTimeZoneAbbreviation() {
    const date = new Date();
    const timeZoneString = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    return timeZoneString;
}

interface SaveScreenProps {
    onBack: () => void;
    onConfirm: (triggerDaily: boolean, time: Date) => void;
}

export function SaveScreen(props: SaveScreenProps) {
    const [triggerDaily, setTriggerDaily] = useState<boolean>(false);
    const [time, setTime] = useState<string | null>(null); // Use string to store time in HH:mm format

    const timeZoneAbbreviation = getTimeZoneAbbreviation();
    const timeZoneCityStyle = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleConfirm = () => {
        if (triggerDaily && time) {
            const [hours, minutes] = time.split(':');
            const now = new Date();
            now.setHours(parseInt(hours), parseInt(minutes), 0, 0); // Set the hours and minutes without changing the date
            props.onConfirm(triggerDaily, now);
        } else if (!triggerDaily) {
            const now = new Date();
            props.onConfirm(false, now); // Use current time as fallback
        }
    };

    const isConfirmDisabled = triggerDaily && time === null;

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            <Typography variant="h4" textAlign={'center'}>Save Routine</Typography>
            <Typography variant="h6" textAlign={'center'} marginTop={'20px'}>You can save this routine and run it manually.<br/><br/> Automatically calling at a fixed time everyday hasn't been implemented yet but is a WIP.</Typography>

            <FormControlLabel
                control={
                    <Switch
                        checked={triggerDaily}
                        onChange={(event) => {
                            setTriggerDaily(event.target.checked);
                            if (!event.target.checked) {
                                setTime(null); // Reset time if the toggle is turned off
                            }
                        }}
                        disabled={true} //TODO: Implement this when it's ready
                    />
                }
                label="Trigger Daily"
                sx={{ marginTop: '20px' }}
            />

            {triggerDaily && (
                <Stack direction={'column'} alignItems="center" sx={{ marginTop: '20px', width: '100%' }}>
                    <TextField
                        type="time"
                        label="Select Time"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={time ?? ""}
                        onChange={(event) => setTime(event.target.value)} // Store the time as a string in HH:mm format
                        sx={{ width: '200px', backgroundColor: 'white' }}
                    />
                    <Typography sx={{ marginTop: '10px' }}>
                        Your Time Zone: {timeZoneAbbreviation}
                    </Typography>
                    <Typography sx={{ marginTop: '5px' }}>
                        ({timeZoneCityStyle})
                    </Typography>
                </Stack>
            )}

            <Stack direction={'row'} spacing={2} sx={{ marginTop: '30px' }}>
                <Button variant="contained" onClick={props.onBack}>Back</Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleConfirm}
                    disabled={isConfirmDisabled}
                >
                    Confirm
                </Button>
            </Stack>
        </Container>
    );
}
