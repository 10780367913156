import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection, getDocs, query, where, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD6-aU_uME6OioiQdJZx22F4LItcxUiKW8",
  authDomain: "bitsandbots.firebaseapp.com",
  projectId: "bitsandbots",
  storageBucket: "bitsandbots.appspot.com",
  messagingSenderId: "889167914862",
  appId: "1:889167914862:web:8decea2efcb8e69d68c37f",
  measurementId: "G-F9WE4J599Q"
};

export interface Routine {
  id?: string | null;
  userId: string;
  routineName: string;
  tasks: string[];
  reminderTime: Date;
  repeatDaily: boolean;
  personName: string;
  phoneNumber: string;
  shouldSendEmail: boolean;  // Flag to indicate if an email should be sent
  emailAddress: string;     // Email address to send notifications to
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export async function deleteRoutine(routineId: string): Promise<void> {
  try {
      const routineRef = doc(db, 'routines', routineId);
      await deleteDoc(routineRef);
      console.log('Routine deleted:', routineId);
  } catch (error) {
      console.error('Error deleting routine:', error);
  }
}

export async function fetchRoutines(userId: string): Promise<Routine[]> {
  const routines: Routine[] = [];
  try {
    const q = query(collection(db, 'routines'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      routines.push({
        id: doc.id ?? null,
        userId: data.userId,
        routineName: data.routineName,
        tasks: data.tasks,
        reminderTime: data.reminderTime.toDate(), // Convert Firestore timestamp to Date
        repeatDaily: data.repeatDaily,
        personName: data.personName,
        phoneNumber: data.phoneNumber,
        shouldSendEmail: data.shouldSendEmail ?? false, // Use false if shouldSendEmail is undefined
        emailAddress: data.emailAddress ?? "",
      });
    });

    console.log('Fetched routines:', routines);
  } catch (error) {
    console.error('Error fetching routines:', error);
  }

  return routines;
}

export async function saveRoutine(routine: Routine) {
  try {
    if (routine.id) {
      console.log('updating existing routine with id', routine.id)
      await updateDoc(doc(db, "routines", routine.id), {
        userId: routine.userId,
        routineName: routine.routineName,
        tasks: routine.tasks,
        reminderTime: routine.reminderTime,
        repeatDaily: routine.repeatDaily,
        personName: routine.personName,
        phoneNumber: routine.phoneNumber,
        shouldSendEmail: routine.shouldSendEmail,    // Save shouldSendEmail flag
        emailAddress: routine.emailAddress          // Save emailAddress if provided
      });
    }
    else {
      await addDoc(collection(db, "routines"), {
        userId: routine.userId,
        routineName: routine.routineName,
        tasks: routine.tasks,
        reminderTime: routine.reminderTime,
        repeatDaily: routine.repeatDaily,
        personName: routine.personName,
        phoneNumber: routine.phoneNumber,
        shouldSendEmail: routine.shouldSendEmail,    // Save shouldSendEmail flag
        emailAddress: routine.emailAddress,          // Save emailAddress if provided
    });
    }
    console.log('Routine saved successfully');
  } catch (error) {
    console.error('Error saving routine:', error);
  }
}

export async function fetchAllSavedRoutines(userId: string): Promise<Routine[]> {
  const routines: Routine[] = [];
  try {
    const q = query(collection(db, 'routines'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      routines.push({
        id: doc.id ?? null,
        userId: data.userId,
        routineName: data.routineName,
        tasks: data.tasks,
        reminderTime: data.reminderTime.toDate(), // Convert Firestore timestamp to Date
        repeatDaily: data.repeatDaily,
        personName: data.personName,
        phoneNumber: data.phoneNumber,
        shouldSendEmail: data.shouldSendEmail ?? false, // Use false if shouldSendEmail is undefined
        emailAddress: data.emailAddress ?? "",
      });
    });

    console.log('Fetched routines:', routines);
  } catch (error) {
    console.error('Error fetching routines:', error);
  }

  return routines;
}


const blandHeaders = {
  'Authorization': 'sk-e4dczy3jflt2mgj4354ds21wiqslng4yluya9fcn3cz7bmlnqj0z5bk77fgi8cgg69'
};

const blandCallData = {
  "phone_number": "",
  "from": "+14153225299",
  "task": "",
  "model": "enhanced",
  "language": "en",
  "voice": "nat",
  "voice_settings": {},
  "pathway_id": null,
  "local_dialing": false,
  "max_duration": 6,
  "answered_by_enabled": false,
  "wait_for_greeting": true,
  "record": false,
  "amd": false,
  "interruption_threshold": 100,
  "voicemail_message": null,
  "temperature": null,
  "transfer_phone_number": null,
  "transfer_list": {},
  "metadata": {},
  "pronunciation_guide": [],
  "start_time": null,
  "request_data": {},
  "tools": [],
  "dynamic_data": [],
  "analysis_schema": {},
  "webhook": "https://completecall-lkjcclx3ma-uc.a.run.app",  
  "calendly": {}
};

interface Params {
  numberToCall: string;
  taskList: string[];
  personName: string;
  routineName: string;
  emailToNotify: string;
  shouldSendEmail: boolean;  // Flag to indicate if an email should be sent
}

export async function makeCall(params: Params) {
  const taskListStringified = params.taskList
    .map((task, index) => `${index + 1}. ${task}`)
    .join('\n');

  const task = `OBJECTIVES
1. You are an AI employed to help ${params.personName} complete their routine for ${params.routineName}.
2. Follow the task list, but DO NOT mention the next task until you have gotten a clear confirmation that ${params.personName} has finished the current task. Do not bring up the next task.
3. DO NOT end the call until you have gone through all of the tasks in the list.
4. Introduce yourself as Echo the AI and greet ${params.personName} by name.

Remember that this isn't perfect and that certain situations or objections may come up. Handle them with grace and bring the conversation back to finishing the [TASK].

LIST OF TASKS
Here is the list of tasks you are going to go through. DO NOT proceed with a task until ${params.personName} has confirmed that they have finished the prior task. Do not bring up the next task until ${params.personName} has clearly stated that they finished the last task.

${taskListStringified}

After all the tasks are completed, say Good Job then hang up immediately.

For example
You: Great. You've completed all the tasks. Good job!
(Hangup)
`;

  blandCallData.phone_number = params.numberToCall;
  blandCallData.task = task;

  try {
    console.log("Making API Call. Task: ", blandCallData.task);
    const response = await axios.post('https://api.bland.ai/v1/calls', blandCallData, { headers: blandHeaders });
    console.log(response.data);
  
    // Write Call Details to Firestore
    console.log("Writing to firestore: ", response.data.call_id);
    await addDoc(collection(db, 'Calls'), {
      callId: response.data.call_id,
      routineName: params.routineName,
      taskList: params.taskList,
      personName: params.personName,
      phoneNumber: params.numberToCall,
      emailToNotify: params.emailToNotify || '',
      shouldSendEmail: params.shouldSendEmail,  // Save shouldSendEmail flag
      timestamp: new Date(),
    });
    console.log('Call details logged to Firestore');
  } catch (error) {
    console.error('Error making the API call or writing to Firestore:', error);
  }
  
}
