import { Typography, Container, Divider, TextField, Stack, Button, Switch, FormControlLabel, IconButton } from "@mui/material";
import { useState } from "react";
import { makeCall } from "./serverLogic";
import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';


interface TaskFieldProps {
    index: number;
    task: string;
    onDelete: (index: number) => void;
    onEdit: (index: number, newTask: string) => void;
}


function TaskField(props: TaskFieldProps) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTask, setEditedTask] = useState(props.task);

    const handleSave = () => {
        if (editedTask.trim() !== "") {
            props.onEdit(props.index, editedTask);
            setIsEditing(false);
        }
    };

    return (
        <Stack direction={'row'} spacing={2}>
            <TextField
                value={isEditing ? editedTask : `${props.index + 1}) ${props.task}`}
                onChange={(event) => setEditedTask(event.target.value)}
                multiline // Enables text wrapping
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    '& .Mui-disabled': {
                        WebkitTextFillColor: 'black', // For WebKit browsers
                        color: 'black', // For other browsers
                    },
                }}
                disabled={!isEditing}
                InputProps={{
                    sx: {
                        color: 'black',
                        '&.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                        },
                    },
                }}
            />
            {isEditing ? (
                <IconButton onClick={handleSave}>
                    <SaveIcon />
                </IconButton>
            ) : (
                <IconButton onClick={() => setIsEditing(true)}>
                    <EditIcon />
                </IconButton>
            )}
            <IconButton onClick={() => props.onDelete(props.index)}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    );
}

interface Props {
    onCall: () => void;
    onSave: () => void;
    onGoToSavedRoutines: () => void;
    routineName: string;
    setRoutineName: (name: string) => void;
    personName: string;
    setPersonName: (name: string) => void;
    phoneNumber: string;
    setPhoneNumber: (number: string) => void;
    createdTasks: string[];
    setCreatedTasks: (tasks: string[]) => void;
    shouldSendEmail: boolean;
    setShouldSendEmail: (value: boolean) => void;
}

export function RoutineBuilder(props: Props) {
    const [newTask, setNewTask] = useState<string>("");

    const user = useAuth0()
    const email = user.user?.email || "";

    const addTask = () => {
        if (newTask.trim() !== "") {
            const taskToAdd = newTask.trim().replaceAll(',', '')
            props.setCreatedTasks([...props.createdTasks, taskToAdd]);
            setNewTask("");
        }
    };

    const editTask = (index: number, newTask: string) => {
        const updatedTasks = props.createdTasks.map((task, i) =>
            i === index ? newTask : task
        );
        props.setCreatedTasks(updatedTasks);
    };

    const deleteTask = (index: number) => {
        const newTaskFields = props.createdTasks.filter((_, i) => i !== index);
        props.setCreatedTasks(newTaskFields);
    };

    const isCallButtonDisabled = () => {
        return (
            props.routineName.trim() === "" ||
            props.personName.trim() === "" ||
            props.phoneNumber.trim() === "" ||
            props.phoneNumber.trim().length < 10 ||
            props.createdTasks.length === 0
        );
    };

    function onCall() {
        let parsedPhoneNumber = props.phoneNumber.trim();

        // Remove any non-numeric characters except the plus sign
        parsedPhoneNumber = parsedPhoneNumber.replace(/[^0-9+]/g, '');

        // If the phone number is 10 digits long and doesn't start with a country code, add +1 (for US/Canada)
        if (parsedPhoneNumber.length === 10 && !parsedPhoneNumber.startsWith('+')) {
            parsedPhoneNumber = '+1' + parsedPhoneNumber;
        }

        // If the phone number starts with a country code and is valid (length 11 or more), leave it as is
        if (parsedPhoneNumber.startsWith('+') && parsedPhoneNumber.length >= 11) {
            console.log(`Calling ${props.personName} at ${parsedPhoneNumber} with routine: ${props.routineName}`);
            makeCall({ 
                numberToCall: parsedPhoneNumber, 
                taskList: props.createdTasks, 
                personName: props.personName, 
                routineName: props.routineName,
                emailToNotify: props.shouldSendEmail ? email : "", // Replace with actual logic to fetch user's email
                shouldSendEmail: props.shouldSendEmail,
            });
            props.onCall();
        } else {
            console.error('Invalid phone number format');
        }
    }

    return (
        <Container>
            <Typography variant="h3" textAlign={'center'}>AI Coach</Typography>
            <Typography variant="h6" textAlign={'center'} marginTop={'20px'}>
                This is an AI Chatbot that will guide someone through a routine (like doing laundry or their bedtime).
                It will call them and make sure they complete all of these steps.
            </Typography>            

            <Typography variant="h4" textAlign={'center'} marginTop={'20px'} marginBottom={'20px'}>Task List</Typography>
            {props.createdTasks.map((task, index) => (
                <TaskField
                    onEdit={editTask}
                    key={index}
                    index={index}
                    task={task}
                    onDelete={deleteTask}
                />
            ))}
            <Stack sx={{ marginTop: '40px' }} direction={'row'} alignItems="center">
                <Typography sx={{ minWidth: '150px' }}>Enter New Task:</Typography>
                <TextField
                    placeholder="Brush your teeth"
                    value={newTask}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewTask(event.target.value);
                    }}
                    sx={{ backgroundColor: 'white', width: '100%' }}
                />
                <Button onClick={addTask} variant='contained'>Add</Button>
            </Stack>

            <Divider sx={{ width: '100%', borderBottomWidth: 1, margin: '20px 0', borderColor: 'black' }} />

            <Stack spacing={2}>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ minWidth: '150px' }}>Routine Name:</Typography>
                    <TextField
                        placeholder="Bedtime Routine"
                        value={props.routineName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setRoutineName(event.target.value)}
                        sx={{ backgroundColor: 'white', flexGrow: 1 }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ minWidth: '150px' }}>Person's Name:</Typography>
                    <TextField
                        placeholder="Jonathan"
                        value={props.personName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setPersonName(event.target.value)}
                        sx={{ backgroundColor: 'white', flexGrow: 1 }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ minWidth: '150px' }}>Phone Number:</Typography>
                    <TextField
                        placeholder="+11234567890"
                        value={props.phoneNumber}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setPhoneNumber(event.target.value)}
                        sx={{ backgroundColor: 'white', flexGrow: 1 }}
                    />
                </Stack>
                <FormControlLabel
                    control={
                        <Switch
                            checked={props.shouldSendEmail}
                            onChange={(event) => {
                                props.setShouldSendEmail(event.target.checked);                            
                            }}                        
                        />
                    }
                    label="Send Email Report When Complete"
                    sx={{ marginTop: '20px' }}
                />
                <Stack gap={'20px'} alignItems={'center'}>
                    <Button
                        variant="contained"
                        disabled={isCallButtonDisabled()}
                        onClick={onCall}
                        sx={{ width: '100%' }}
                    >
                        Call
                    </Button>
                    <Button variant="contained"
                        onClick={props.onSave}
                        sx={{ width: '100%' }}
                        disabled={isCallButtonDisabled()}
                    >
                        Save
                    </Button>
                    <Button variant="contained"
                        onClick={props.onGoToSavedRoutines}
                        sx={{ width: '100%' }}                        
                    >
                        Saved Routines
                    </Button>
                </Stack>

            </Stack>
        </Container>
    );
}
