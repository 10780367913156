import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Typography, CircularProgress, Box, Card, CardContent, Button } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { Routine, makeCall, fetchRoutines, deleteRoutine } from "./serverLogic";

interface Props {
    onBack: () => void;
    onCall: () => void;
    onEdit: (routine: Routine) => void;
}

export default function RoutinesScreen(props: Props) {
    const [routines, setRoutines] = useState<Routine[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const user = useAuth0();

    useEffect(() => {
        async function loadRoutines() {
            const userId = user.user?.sub;

            if (!userId) {
                console.error('No user is signed in');
                return;
            }

            try {
                const routinesData = await fetchRoutines(userId);
                setRoutines(routinesData);
            } catch (error) {
                console.error('Error loading routines:', error);
            } finally {
                setLoading(false);
            }
        }

        loadRoutines();
    }, [user]);

    const handleCallButtonClick = (routine: Routine) => {
        props.onCall();
        makeCall({
            numberToCall: routine.phoneNumber,
            taskList: routine.tasks,
            personName: routine.personName,
            routineName: routine.routineName,
            emailToNotify: routine.emailAddress || "",
            shouldSendEmail: routine.shouldSendEmail
        });
    };

    const handleDeleteButtonClick = async (routineId: string) => {
        try {
            await deleteRoutine(routineId);
            setRoutines(routines.filter(routine => routine.id !== routineId));
        } catch (error) {
            console.error('Error deleting routine:', error);
        }
    };

    const handleEditButtonClick = (routine: Routine) => {
        props.onEdit(routine);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (routines.length === 0) {
        return (
            <Box textAlign="center" mt={5}>
                <Typography variant="h6">No routines found</Typography>
                <Button onClick={props.onBack} variant='contained'>Go Back</Button>
            </Box>
        );
    }

    return (
        <Box m={3}>
            <Button onClick={props.onBack} variant='contained'>Go Back</Button>
            <Typography variant="h4" textAlign="center" gutterBottom>
                Your Routines
            </Typography>
            <List>
                {routines.map((routine) => (
                    <Card key={routine.routineName} style={{ marginBottom: '16px' }}>
                        <CardContent>
                            <Typography variant="h6">{routine.routineName}</Typography>
                            <List>
                                {routine.tasks.map((task, index) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemText
                                            primary={`${index + 1}. ${task}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <Typography variant="body2">
                                {`Email Notifications: ${routine.shouldSendEmail ? 'Yes' : 'No'}`}
                            </Typography>
                            <Typography variant="body2">
                                {`Repeat Daily: ${routine.repeatDaily ? 'Yes' : 'No'}`}
                            </Typography>
                            {routine.repeatDaily && (
                                <Typography variant="body2">
                                    {`Time: ${new Intl.DateTimeFormat(undefined, {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }).format(new Date(routine.reminderTime))}`}
                                </Typography>
                            )}
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleCallButtonClick(routine)}
                                >
                                    Call
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleEditButtonClick(routine)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleDeleteButtonClick(routine.id!)}
                                    style={{ marginLeft: '8px' }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </List>
        </Box>
    );
}
