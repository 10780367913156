import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { RoutineBuilder } from "./RoutineBuilder";
import { CallingScreen } from "./CallingScreen";
import { SaveScreen } from "./SaveScreen";
import RoutinesScreen from "./SavedRoutines";
import { useAuth0 } from "@auth0/auth0-react";
import { Routine, saveRoutine } from "./serverLogic";


enum VoiceCoachState {
    LOGIN,
    CREATE,
    CALLING,
    SAVING,
    PREVIOUSLY_SAVED
}

export function VoiceCoach() {
    const [state, setState] = useState<VoiceCoachState>(VoiceCoachState.CREATE);

    const [routineName, setRoutineName] = useState<string>("");
    const [personName, setPersonName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [createdTasks, setCreatedTasks] = useState<string[]>([]);
    const [editingRoutineId, setEditingRoutineId] = useState<string | null | undefined>(null);
    const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(true);

    const user = useAuth0()
    const email = user.user?.email || "";

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    function onCall() {
        setState(VoiceCoachState.CALLING);
    }

    function onSave() {
        setState(VoiceCoachState.SAVING);
    }

    function onGoToSavedRoutines() {
        setState(VoiceCoachState.PREVIOUSLY_SAVED);
    }

    function resetAll() {
        setRoutineName("");
        setPersonName("");
        setPhoneNumber("");
        setCreatedTasks([]);
        setShouldSendEmail(false);
    }

    function onEdit(routine: Routine) {
        // Set the fields with the routine data to be edited
        setRoutineName(routine.routineName);
        setPersonName(routine.personName);
        setPhoneNumber(routine.phoneNumber);
        setCreatedTasks(routine.tasks);
        setEditingRoutineId(routine.id)
        setShouldSendEmail(routine.shouldSendEmail);
        // Navigate to the RoutineBuilder screen
        setState(VoiceCoachState.CREATE);
    }

    function onConfirmSave(triggerDaily: boolean, time: Date) {
        const userId = user.user?.sub
        if (!userId) {
            console.error("User ID not found");
            return;
        }

        const routine: Routine = {
            id: editingRoutineId ?? null,
            userId: userId,
            routineName: routineName,
            tasks: createdTasks,
            reminderTime: time,
            repeatDaily: triggerDaily,
            personName: personName,
            phoneNumber: phoneNumber,
            shouldSendEmail: shouldSendEmail,
            emailAddress: email
        };

        saveRoutine(routine);
        console.log("Trigger Daily:", triggerDaily);
        console.log("Scheduled Time:", time.toISOString());

        resetAll();
        setState(VoiceCoachState.CREATE);
    }

    if (state === VoiceCoachState.CREATE) {
        return (
            <RoutineBuilder
                onCall={onCall}
                routineName={routineName}
                setRoutineName={setRoutineName}
                personName={personName}
                setPersonName={setPersonName}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                createdTasks={createdTasks}
                setCreatedTasks={setCreatedTasks}
                shouldSendEmail={shouldSendEmail}
                setShouldSendEmail={setShouldSendEmail}
                onSave={onSave}
                onGoToSavedRoutines={onGoToSavedRoutines}
            />
        );
    } else if (state === VoiceCoachState.CALLING) {
        return (
            <CallingScreen onBack={() => { setState(VoiceCoachState.CREATE); }} />
        );
    } else if (state === VoiceCoachState.SAVING) {
        return (
            <SaveScreen
                onBack={() => { setState(VoiceCoachState.CREATE); }}
                onConfirm={onConfirmSave}
            />
        );
    } else if (state === VoiceCoachState.PREVIOUSLY_SAVED) {
        return (
            <RoutinesScreen onBack={() => setState(VoiceCoachState.CREATE)}
                onCall={() => { setState(VoiceCoachState.CALLING) }} 
            onEdit={onEdit}/>
        );
    }

    return (
        <Typography textAlign={'center'}>
            ERROR!
        </Typography>
    );
}
